.modal {
    background: rgba(0, 0, 0, .6);
    width: 100vw;
    height: 100vh;
    z-index: 9999; /* Maior que o header e a sidebar */
    position: fixed; /* Garante que o modal fique fixo na tela */
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center; /* Para centralizar o conteúdo */
}

.modal article {
    padding: 1rem 2rem;
    background: #fff;
    border-radius: .4rem;
    width: 70rem;
    height: 35rem;
}

.modal header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal header button{
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    background: #ededed;
    border: none;
    cursor: pointer;
    font-size: 22px;
    box-shadow: 1px 0 3px rgba(0, 0, 0, .2);
}

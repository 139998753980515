.login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #1E310F;
    min-height: 89vh;
}

.login::before {
    content: '';
    width: 100%;
    min-height: 86.6vh;
    display: block;
    background: url('../../assets/images/image-login.png') no-repeat center center;
    background-size: cover;
}

.login form {
    padding: 5rem 8rem;
}

.login form h1 {
    color: #fff !important;
    text-align: center !important;
}

.login form h1::after {
    left: 13.5rem !important;
}

.login p {
    color: #fff;
    margin-bottom: .5rem;
    margin-top: 1rem;
}

.paragrafo {
    color: #fff;
    text-align: center;
}

/* responsividade */

@media (max-width: 1440px) {
    .login {
        min-height: 90vh !important;
    }

    .login form {
        padding: 5rem 1rem;
    }

    .login form h1::after {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .login {
        grid-template-columns: 1fr;
        min-height: 91vh !important;
    }

    .login form {
        padding: 5rem !important;
    }

    .login::before {
        content: '';
        width: 100%;
        display: none;
    }

    .login form h1::after {
        display: none !important;
    }
}

@media (max-width: 40rem) {
    .login form {
        padding: 5rem 1rem !important;
    }
}
.formGroup{
    width: 15.5rem;
    margin-right: 1rem;
}

.btnAdd{
    font-size: 16px !important;
    padding: 1.1rem 2rem !important;
    cursor: pointer;
}

.gridSystem {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: .25rem;
}

.cardHeader {
    border-radius: .25rem .25rem 0 0;
}

@media (max-width: 1196px) {
    .gridSystem {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
}

@media (max-width: 922px) {
    .gridSystem {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }
}

@media (max-width: 460px) {
    .gridSystem {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }
}
/* geral */
.wrapper {
    margin: 1rem;
    display: flex;
}

/* primeiro bloco */
.description {
    background: #fff;
    margin-right: 1rem;
    padding: 1rem;
    border: 1px solid #ededed;
    border-radius: .5rem;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
    width: 70%;
}

/* segundo bloco */
.datas {
    width: 30%;
}

.blockCards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.blockCards .card {
    width: 50%;
    background: #fb1;
}

.turma {
    margin-right: 1rem;
    background: #fff !important;
}

.blockDisciplinas {
    background: #fff;
    padding: 1rem;
    border: 1px solid #ededed;
    border-radius: .5rem;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}

.blockDisciplinas h3 {
    font-size: 1.2rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #333;
    border-bottom: 1px solid #0000004d;
    padding-bottom: .5rem;
}

/* responsividade */

@media (max-width: 890px) {
    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .description {
        margin-bottom: 1rem;
        width: 100%;
    }

    .datas {
        width: 100%;
    }

    .turma {
        margin-right: .5rem !important;
    }
}
.oneBloco {
    margin: 1rem;
    background: #fff;
    border-radius: 1rem;
    padding-bottom: 1rem;
}

.bg {
    background: #E19E06;
    width: 100%;
    height: 8rem;
    border-radius: .5rem .5rem 0 0;
    position: relative;
}

.avatar {
    width: 10rem !important;
    height: 10rem !important;
    border: 4px solid #fff;
    position: absolute;
    top: -5rem;
    left: 1.5rem;
}

.discriptioBloco {
    margin-top: -4rem;
    margin-left: 1rem;
    font-size: 16px;
}


.discriptioBloco div {
    display: flex;
}

.discriptioBloco div p {
    margin-right: 1rem;
}

.twoBloco {
    display: flex;
    border-radius: .5rem;
    margin: 1rem;
    padding-bottom: 1rem;
}

.table{
    background: #fff;
    border-radius: .5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    width: 100%;
}

.table table {
    width: 100%;
    border: 1px solid #eee;
    border-collapse: collapse;
    text-align: center;
    border-radius: .5rem !important;
}

.table table thead {
    background: #E19E06;
    border-radius: .5rem !important;
}

.table table thead tr {
    padding: .5rem auto;
}

.table table thead tr th,
.table table thead tr td {
    color: #333;
    padding: 1rem auto;
}

.skills {
    width: 30%;
}

.skills ul {
    list-style: none;
    margin-left: 10px;
}

.skills ul li {
    position: relative;
}

.skills ul li::before{
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #346CB0;
    border-radius: 5rem;
    position: absolute;
    top: 10px;
    left: -10px;
}
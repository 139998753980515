.button{
    background: #fb1;
    padding: 1rem 3rem;
    border: 1px solid transparent;
    border-radius: .3rem;
    cursor: pointer;
    margin-top: 1.5rem;
    font-size: 16px;
    width: 100%;
    text-transform: uppercase;
    color: #1E310F;
}

.button:hover{
    outline: none;
    border-color: #fea;
    box-shadow: 0 0 0 3px #fb5;
}
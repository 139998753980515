.wrapper {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    width: 100%;
}
.wrapper label {
    display: block;
    font-size: 16px;
    color: #333;
}

.wrapper input {
    display: block;
    width: 100%;
    padding: 1rem .5rem;
    background: #eee;
    border: 1px solid transparent;
    border-radius: .2rem;
    font-size: 16px;
}

.wrapper input:hover,
.wrapper input:focus {
    background: #fff;
    border-color: #fb1;
    box-shadow: 0 0 0 3px #fea;
}

.wrapper input::placeholder {
    font-size: 16px;
}

.wrapper .errorMessageBorder {
    border: 1px solid rgb(209, 32, 32);
}

.wrapper .errorMessage {
    color: rgb(209, 32, 32);
    
}

.wrapper .errorMessageBorder::placeholder {
    color: rgb(209, 32, 32);
}

@media (max-width: 40rem) {
    .wrapper {
        display: flex;
        flex-direction: column;
        margin: .5rem auto;
        width: 100%;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.home {
    background: #EBF0F6;
}

/* article one */
.articleOne {
    margin: 5rem 8rem;
    display: flex;
}

.articleOne .blobkImage {
    width: 50%;
    padding: .5rem;
    border-radius: .5rem;
    margin-right: 1rem;
    background: #fff;
}

.articleOne img {
    width: 100%;
    height: 100%;
    border-radius: .3rem;
}

.articleOne .blobkText {
    width: 50%;
}

.articleOne .blobkText p {
    margin-top: 1rem;
    font-size: medium;
}

.button {
    background: #E19E06;
    padding: .8rem 1.5rem;
    border: 1px solid transparent;
    color: #333;
    font-size: 16px;
    border-radius: .4rem;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 1rem;
}

.button:hover {
    outline: none;
    border-color: #E19E06;
    box-shadow: 0 0 0 3px #fb1;
}

/* responsividade */

@media (max-width: 1050px) {

    .articleOne {
        margin: 5rem 5rem;
    }
}

@media (max-width: 820px) {

    .articleOne {
        margin: 2rem;
        display: flex;
        flex-direction: column;
    }

    .articleOne img,
    .articleOne .blobkImage,
    .articleOne .blobkText {
        width: 100% !important;
    }

    .blobkText {
        margin-top: 1rem;
    }
}

/* article two */
.articleTwo {
    background: #1E310F;
    padding: 0 8rem;
    display: flex;
    position: relative;
}

.articleTwo .blobkImage,
.articleTwo .blobkText {
    width: 50%;
}

.articleTwo .blobkText {
    padding: 5rem 0;
}

.articleTwo h2,
.articleTwo p,
.articleTwo ul li {
    color: #fff;
}

.articleTwo ul li {
    display: flex;
}

.articleTwo ul li i {
    color: #E19E06;
    margin-right: .5rem;
}

.articleTwo h2 {
    margin-bottom: 1rem;
}

.articleTwo ul {
    list-style: none;
}

.articleTwo ul li {
    margin-bottom: 1rem;
    max-width: 500px;
}

.imageObjective {
    width: 70rem;
    height: 44rem;
    position: absolute;
    top: -9.6rem;
    right: -10rem;
}

@media (max-width: 1261px) {
    .articleTwo {
        padding: 0 2rem;
        margin-bottom: -5rem;
    }

    .articleTwo .blobkImage,
    .articleTwo .blobkText {
        width: 100%;
    }

    .articleTwo .blobkImage {
        display: none;
    }

    .articleTwo .blobkText ul {
        display: grid;
        gap: 2rem;
    }

    .articleTwo .blobkText ul li {
        display: block;
        max-width: 100% !important;
        margin-bottom: 0 !important;
    }
}

/* article three */
.articleThree {
    background: #1E310F;
    padding: 5rem 8rem;
    display: flex;
    margin-top: 10rem;
    position: relative;
}

.articleThree .blobkImage,
.articleThree .blobkText {
    width: 50%;
}

.articleThree ul li {
    display: flex;
}

.articleThree ul li i {
    color: #E19E06;
    margin-right: .5rem;
}

.articleThree h2 {
    margin-bottom: 1rem;
    color: #fff;
}

.articleThree ul {
    list-style: none;
}

.articleThree ul li {
    margin-bottom: 1rem;
    max-width: 500px;
    color: #fff;
}

.imageExigencia {
    width: 60rem;
    height: 38rem;
    position: absolute;
    top: -10rem;
    left: -6.8rem;
}

@media (max-width: 1261px) {
    .articleThree {
        padding: 2rem 2rem 4rem 2rem;
    }

    .articleThree .blobkImage,
    .articleThree .blobkText {
        width: 100%;
    }

    .articleThree .blobkImage {
        display: none;
    }

    .articleThree .blobkText ul {
        display: grid;
        gap: 2rem;
    }

    .articleThree .blobkText ul li {
        display: block;
        max-width: 100% !important;
        margin-bottom: 0 !important;
    }
}

/* articleFor */
.articleFor {
    /* margin-bottom: 5rem; */
}

.articleFor div:nth-child(1) {
    padding: 5rem 10rem;
}

.bannerBlock {
    margin-top: -4rem;
    width: 100%;
    height: 70vh;
    padding: 5rem 10rem;
    background: url('../assets/images/image-escolas.png') no-repeat center center;
    background-size: cover;
    color: #fff;
    position: relative;
}

.bannerBlock::before {
    content: '';
    display: block;
    width: 100%;
    height: 70vh;
    background: rgb(0, 0, 0, .5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.bannerBlock>* {
    position: relative;
    z-index: 2;
}

.bannerBlock p {
    margin-top: 1rem;
}

.bannerBlock p i {
    color: #E19E06;
    margin-right: .3rem;
}

.bannerBlock p span {
    margin-left: 1rem;
}

@media (max-width: 1261px) {
    .articleFor .bannerBlock {
        padding: 3rem 2rem;
    }

    .articleFor .bannerBlock h2{
        margin-bottom: 1rem;
    }

    .articleFor div:nth-child(1) {
        padding: 3rem 2rem 5rem 2rem;
    }
}
@media (max-width: 40rem) {
    .bannerBlock {
        height: 85vh;
    }

    .bannerBlock::before {
        height: 85vh;
    }
    
}
.register{
    padding: 2rem 8rem;
    background: #f5f5f5;
    min-height: 100vh;
}

.subtitle{
    margin-bottom: 1rem;
    margin-bottom: 2rem;
}

.blockInput{
    display: flex;
}

.blockInput > div:nth-child(1){
    margin-right: 1rem;
}

.blockBtn{
    display: flex;
}

.blockBtn > button:nth-child(1){
    margin-right: 1rem;
}
.bockChart {
    width: 100%;
    display: flex;
}

.articleGrafico {
    background: #fff;
    border: 1px solid #ededed;
    width: 50%;
    border-radius: .3rem;
    padding: 1.5rem .5rem 0 .5rem;
}

.pie {
    padding: 2rem;
}

@media (max-width: 890px) {
    .bockChart{
        display: flex;
        flex-direction: column;
    }
    .articleGrafico {
        width: 100%;
    }
    .pie{
        margin-top: 1rem;
    }
}